import { Badge, Col, Layout, Modal, Row, Spin } from 'antd'
import { FC, useEffect, useRef, useState } from 'react'
import { Route, RouteComponentProps, withRouter } from 'react-router'
import Shell from 'src/core/ui/shell'
import SecureContent from '../../core/services/authentication'
import { AppConfiguration, ConfigurationService } from '../../core/services/configuration.service'
import { container } from '../../inversify.config'
import Home from './home'
import logo from 'src/core/assets/logo.png'
import footerLogo from 'src/core/assets/footer-logo.png'
import ShellIdleApp from 'src/core/ui/shell-idle-app'
import ShellDisclaimer from '../../core/ui/shell-disclaimer'
import { LoadingOutlined, OrderedListOutlined } from '@ant-design/icons'
import { WithTranslation, withTranslation } from 'react-i18next'
import ShellExpirationToken from '../../core/ui/shell-expiration-token'
import { AppInfo } from './menu'
import { arrayMoveImmutable } from 'array-move'
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc'
import { CacheProps, TimeType, withCache } from '../../core/services/cache.service'
import HttpService from '../../core/services/http.service'
import React from 'react'
import { QueryResult } from '../../core/stores/data-store'

interface SortAppListProps extends WithTranslation, CacheProps {
  apps: AppInfo[]
  onOrderChange: (apps: AppInfo[]) => void
  onClose: () => void
  showPrerelease: boolean
}



const AppIcon: FC<{ icon?: string, id?: string, cache: CacheProps }> = (props) => {

  const httpService = container.get(HttpService)
  const [busy, setBusy] = React.useState(false)
  const [iconSource64, setIconSource64] = useState(props.icon)
  const inputRef = React.useRef()
  const cacheImageKey = "IMAGESOURCE64"

  React.useEffect(
    () => {
      var cachedImage = props.cache.cache.getGlobal(cacheImageKey + props.id)
      if (cachedImage) {
        setIconSource64(cachedImage as any)
        return
      }
      if (props.id) {
        setBusy(true)
        httpService.get<QueryResult<AppInfo>>("/api/v1/admin/applications/icon/" + props.id).then(result => {
          setIconSource64(result.data as any)
          props.cache.cache.saveGlobal(cacheImageKey + props.id, result.data, { value: 1, type: TimeType.Hour })
          setBusy(false)

        }).catch(error => {
          setBusy(false)
        })
      }
    }, [inputRef]
  )

  return !busy ? <img width={80} height={80} src={iconSource64} alt="icon" /> : <LoadingOutlined style={{ fontSize: "48px", color: "white" }} />
}

const SortAppList: FC<SortAppListProps> = (props) => {

  const httpService = container.get(HttpService)
  const [applications, setApplications] = useState(props.apps.filter(x => !x.prerelease || props.showPrerelease))
  const inputRef = useRef()

  useEffect(
    () => {


    }, [inputRef]
  )

  const DragHandle = SortableHandle(() => <OrderedListOutlined style={{ cursor: 'grab', color: 'white', paddingRight: 15, fontSize: 30, float: "right" }} />)

  const SortableItem = SortableElement(({ value, index }: any) => {
    return (
      <Col className={"sorteable-item"} span={6}>
        <div className={'top-item  noselect fi-light-blue-color  ' + (value.isUserFavorite ? "user-favorite" : "")}>
          <DragHandle />
          <div className="app-icon">
            <AppIcon id={value.id} cache={props} />
          </div>
          <div className="app-name">
            {value.name}
          </div>
        </div>
      </Col>
    )
  })

  const SortableList = SortableContainer(({ items }: any) => {
    return (
      <Row style={{ padding: 20 }} gutter={10}>
        {items.filter(x => !x.prerelease || (props.showPrerelease)).map((value: any, index: number) => (
          <SortableItem key={`item-${index}`} index={index} value={value} />
        ))}
      </Row>
    )
  })

  const onSortEnd = ({ oldIndex, newIndex }: any) => {
    var newItems = arrayMoveImmutable(applications, oldIndex, newIndex)
    setApplications(newItems)
    SetOrder(newItems.map((t) => t.id))
    props.onOrderChange(newItems)
  }

  const SetOrder = async (list: string[]) => {
    await httpService.post(`/api/v1/admin/applications/reorder`, {
      Order: list,
    })
  }


  return <Modal
    className="app-order-modal"
    centered
    style={{ overflow: "hidden" }}
    footer={null}
    width={1000}
    cancelButtonProps={{ hidden: true }}
    okButtonProps={{ hidden: true }}
    onCancel={() => props.onClose()}
    onOk={() => props.onClose()}
    open={true}>
    <SortableList helperClass="dragging-helper-class" axis="xy" transitionDuration={500} items={applications || []} onSortEnd={onSortEnd} useDragHandle />
  </Modal>



}

export default withTranslation()(withCache(SortAppList))
